<template>
  <multiselect
    v-model="selectedProfessional"
    id="professional_selected"
    :options="professionalOptions"
    @select="onProfessionalSelect"
    label="label"
    track-by="value"
    :allow-empty="false"
    :option-height="40"
    :showLabels="false"
    placeholder="Selecionar"
    class="with-border"
    :disabled="readonly"
  >
    <template slot="option" slot-scope="{ option }">
      <div class="custom-option">
        <div class="truncate">
          <span class="d-block">{{ option.label }}</span>
          <p>
            {{ option?.cbo }}
          </p>
        </div>
      </div>
    </template>
    <template slot="caret">
      <div class="chevron">
        <ChevronDown />
      </div>
    </template>
    <template slot="noOptions">
      Nenhum profissional cadastrado, atualize a configuração do convênio
    </template>
    <template slot="noResult">Nenhum profissional encontrado</template>
  </multiselect>
</template>

<script>
export default {
  name: 'ProfessionalFieldModule',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  props: {
    professional: Object,
    clinic: Object,
    settingsId: String,
    multiselect: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    fillProfessionalData: Function, 
    reloadValues: Number,
  },
  async created() {
    await this.getProfessionals();
    await this.setProfessional();
  },
  data() {
    return {
      cbos: require('@/components/Sus/Data/cbos.json'),
      professionalCouncils: require('@/components/Sus/Data/professionalCouncils.json'),
      invoicingSusSettingProfessional: null,
      professionalsArray: [],
      professionalOptions: [],
      selectedProfessional: null
    }
  },
  methods: {
    async getProfessionals() {
      if (this.professionalOptions.length) return;
      try {
        const { data } = await this.api.getActiveSusProfessionals(this.clinic?.id);

        this.professionalsArray = data;

        this.professionalOptions = data.map(item => {          
          const { cbo, cns } = item?.sus_professional_setting;

          return {
            label: item.name,
            value: item.id,
            id: item.id,
            cbo: this.cbos.find(option => option.value === cbo)?.label,
            cns
          }
        });

        this.$emit('change-professionals', this.professionalsArray);

        if (!this.professionalOptions.length)
          this.$toast.warning(
            `Não existe profissionais ativos neste convênio! \n Atualize-o na configuração!`
          );
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async setProfessional() {
      if (this.professional?.id) {
        let foundProfessional = this.professionalOptions.find(item => item.id === this.professional.id);
        
        if (!foundProfessional) {
          foundProfessional = { ...this.professional };
          this.professionalOptions.push(foundProfessional);
        }
        
        this.selectedProfessional = foundProfessional;
      }
    },
    onProfessionalSelect($event) {
      this.$emit('update-professional', $event);
    }
  }
}
</script>

<style lang="scss" scoped>
.option {
  width: 90%;
}
</style>
